import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { useIntl } from 'gatsby-plugin-intl';

const ValueCard = ({ name = 'create' }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 50px 0 0 0;

        width: 300px;
        height: 280px;
        flex: 0 0 auto;
        margin-right: 13px;
        :last-child {
          margin-right: 0;
        }
        ${Tablet} {
          margin-right: 0px;
          width: 300px;
          height: 280px;
        }
        ${Desktop} {
          width: 363px;
          height: 256px;
        }
      `}
    >
      <img height={42} src={`/career/corporate-values/${name}.png`}></img>
      <div
        css={css`
          color: #000000;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          margin-top: 10px;
          margin-bottom: 10px;
          ${Tablet} {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          ${Desktop} {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin-top: 20px;
            margin-bottom: 10px;
          }
        `}
      >
        {intl.formatMessage({ id: `career.corparate-values.${name}.title` })}
      </div>
      <div
        css={css`
          color: #161616;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          width: 265px;
          ${Tablet} {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            width: 265px;
          }
          ${Desktop} {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            width: 259px;
          }
        `}
      >
        {intl.formatMessage({
          id: `career.corparate-values.${name}.description`,
        })}
      </div>
    </div>
  );
};

export default ValueCard;
