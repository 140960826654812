import React, { useRef } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { FormattedHTMLMessage, Link, useIntl } from 'gatsby-plugin-intl';
import Container from '../../components/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CareerJobList from '../../components/CareerJobList';
import ScrollContainer from 'react-indiana-drag-scroll';
import CareerTeamSay from '../../components/CareerTeamSay';
import CareerWorkWithAQM from '../../components/CareerWorkWithAQM';
import CareerCorporatevalues from '../../components/CareerCorporatevalues';
import CareerJoinTheTeam from '../../components/CareerJoinTheTeam';
import Blank from '../../components/Blank';

const StartEndPadding = () => {
  return (
    <div
      css={css`
        flex: 0 0 auto;
        display: block;
        width: 20px;
        ${Desktop} {
          display: none;
        }
      `}
    ></div>
  );
};

const CareerPage = () => {
  const intl = useIntl();
  const jobListRef = useRef(null);
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "career/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      one: file(relativePath: { eq: "career/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      two: file(relativePath: { eq: "career/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      three: file(relativePath: { eq: "career/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      four: file(relativePath: { eq: "career/4.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout footerBlank>
      <SEO
        title={intl.formatMessage({ id: 'career.title' })}
        description={intl.formatMessage({ id: 'career.description' })}
        keywords={intl.formatMessage({ id: 'career.keywords' })}
      />
      <CareerWorkWithAQM jobListRef={jobListRef} />
      <CareerCorporatevalues />
      <CareerJoinTheTeam jobListRef={jobListRef} />
      <Blank height={[80, 150, 150]} />
      <CareerTeamSay />
      <Blank height={[100, 150, 150]} />
      {/* 职位筛选，查询 */}
      <CareerJobList jobListRef={jobListRef} />
      {/* 底部blog文章 */}
      <Container
        css={css`
          margin-top: 100px;
          width: 100% !important;
          padding: 0px;
        `}
      >
        <h1
          css={css`
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
            ${Tablet} {
              text-align: center;
            }
          `}
        >
          <FormattedHTMLMessage id='career.read.title' />
        </h1>
        <ScrollContainer
          onScroll={(e, a, b) => {
            console.log(e, a, b);
          }}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              ${Tablet} {
                justify-content: center;
              }
            `}
          >
            {['one', 'two', 'three', 'four'].map((text, index) => {
              return (
                <Link
                  key={text}
                  to={`/blog/${
                    { one: 490, two: 334, three: 290, four: 117 }[text]
                  }`}
                  css={css`
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 160px;
                    height: 180px;
                    background: #f2f2f2;
                    border-radius: 20px;
                    overflow: hidden;
                    z-index: 0;
                    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.3);
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 20px 0px;
                    margin-left: 20px;
                    ${Tablet} {
                      margin: 20px 12px;
                      width: 160px;
                      height: 180px;
                    }
                    ${Desktop} {
                      width: 270px;
                      height: 290px;
                      margin: 20px 10px;
                    }
                  `}
                >
                  <div
                    css={css`
                      width: 160px;
                      ${Tablet} {
                        width: 160px;
                      }
                      ${Desktop} {
                        width: 270px;
                      }
                    `}
                  >
                    <Img
                      fluid={data[text].childImageSharp.fluid}
                      imgStyle={{ objectFit: 'fill' }}
                      loading='eager'
                    />
                  </div>
                  <div
                    css={css`
                      margin: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                      flex: 1 1 100%;
                      font-weight: bold;
                      font-size: 12px;
                      padding: 0px 1px;
                      ${Desktop} {
                        padding: 0px 16px;
                        font-size: 18px;
                      }
                    `}
                  >
                    <FormattedHTMLMessage
                      id={`career.read.text-${index + 1}`}
                    />
                  </div>
                </Link>
              );
            })}
            <StartEndPadding />
          </div>
        </ScrollContainer>
      </Container>
    </Layout>
  );
};

export default CareerPage;
