import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Container from '../Container';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../Blank';
import TeamCard from './TeamCard';
import { useIntl } from 'gatsby-plugin-intl';
const CareerJoinTheTeam = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      StockTradingMobile: file(
        relativePath: { eq: "home-banner/promotions/stock-trading-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        ${Tablet} {
        }
        ${Desktop} {
        }
      `}
    >
      <Blank height={[90, 100, 150]} />
      <div
        css={css`
          text-align: center;
          color: #000000;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.38;
          margin-bottom: 30px;
          ${Tablet} {
            font-size: 36px;
            font-weight: 500;
            line-height: 1.33;
            margin-bottom: 10px;
          }
          ${Desktop} {
            font-weight: 500;
            font-size: 36px;
            line-height: 33px;
            margin-bottom: 30px;
          }
        `}
      >
        {intl.formatMessage({ id: `career.join-the-team.title` })}
      </div>
      <div
        css={css`
          text-align: center;
          color: #161616;

          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          padding: 0 30px;
          margin-bottom: 50px;
          ${Tablet} {
            padding: 0;
            color: #000000;
            font-size: 18px;
            font-weight: normal;
            line-height: 1.33;
            max-width: 661px;
          }
          ${Desktop} {
            font-size: 24px;
            line-height: 33px;
            max-width: 972px;
          }
        `}
      >
        {intl.formatMessage({ id: `career.join-the-team.description` })}
      </div>
      {/* 各部门介绍卡片 */}
      <div
        css={css`
          ${Tablet} {
            display: flex;
            max-width: 707px;
            justify-content: space-between;
            width: 100%;
          }
          ${Desktop} {
            display: flex;
            max-width: 1205px;
            justify-content: space-between;
            width: 100%;
          }
        `}
      >
        <TeamCard name='Algo' />
        <TeamCard name='ICG' />
        <TeamCard name='IT' />
        <TeamCard name='SO' />
      </div>
    </div>
  );
};

export default CareerJoinTheTeam;
