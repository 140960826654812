import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Blank from '../../components/Blank';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
const TeamCard = ({ name = 'Algo' }) => {
  const intl = useIntl();
  const [showInfo, setShowInfo] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      Algo: file(relativePath: { eq: "career/join-the-team/algo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ICG: file(relativePath: { eq: "career/join-the-team/BD.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      IT: file(relativePath: { eq: "career/join-the-team/IT.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      SO: file(relativePath: { eq: "career/join-the-team/SO.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div
      css={css`
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        background: #ffffff;
        overflow: hidden;
        height: 100%;
        width: 325px;
        margin-bottom: 20px;
        ${Tablet} {
          margin-bottom: 0;
          height: 100%;
          width: 161px;
        }
        ${Desktop} {
          height: 100%;
          width: 288px;
        }
      `}
    >
      {/* 图 */}
      <div
        css={css`
          width: 100%;
          ${Tablet} {
            background: url(${data[name].childImageSharp.fluid.src}) no-repeat;
            background-size: cover;
            background-position: center;
            height: 217px;
            margin-bottom: 10px;
          }
          ${Desktop} {
            height: 345px;
            margin-bottom: 30px;
          }
        `}
      ></div>
      {/* 部门与介绍 */}
      <div
        css={css`
          padding: 20px 8px 20px 16px;
          ${Tablet} {
            width: 100%;
            padding: 0 7px 10px 7px;
          }
          ${Desktop} {
            width: 100%;
            padding: 0 13px 30px 16px;
          }
        `}
      >
        {/* 部门title */}
        <div
          css={css`
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            ${Tablet} {
              font-size: 12px;
              font-weight: 500;
              line-height: 2;
            }
            ${Desktop} {
              font-weight: bold;
              font-size: 20px;
              line-height: 20px;
            }
          `}
        >
          <span>
            {intl.formatMessage({ id: `career.join-the-team.${name}.title` })}
          </span>
          {/* 加减号 */}
          <div
            css={css`
              cursor: pointer;
              height: 100%;
              width: 12px;
            `}
            onClick={() => {
              setShowInfo(!showInfo);
            }}
          >
            {/* 横 */}
            <div
              css={css`
                background: #ee2f79;
                width: 12px;
                height: 2px;
                ${Tablet} {
                  width: 8px;
                  height: 2px;
                }
                ${Desktop} {
                  width: 15px;
                  height: 3px;
                }
              `}
            ></div>
            {/* 竖 */}
            <div
              className={`minus-${name}`}
              css={css`
                display: ${showInfo ? 'none' : 'block'};
                margin-top: -2px;
                transform: rotate(90deg);
                background: #ee2f79;
                width: 12px;
                height: 2px;
                ${Tablet} {
                  width: 8px;
                  height: 2px;
                }
                ${Desktop} {
                  width: 15px;
                  height: 3px;
                }
              `}
            ></div>
          </div>
        </div>
        {/* 部门介绍 */}
        <div
          css={css`
            display: ${showInfo ? 'block' : 'none'};
          `}
        >
          <div
            css={css`
              div {
                margin-bottom: 5px;
              }
              color: #161616;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.71;
              margin-top: 16px;
              ${Tablet} {
                div {
                  margin-bottom: 4px;
                }
                color: #000000;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.67;
                margin-top: 10px;
              }
              ${Desktop} {
                div {
                  margin-bottom: 10px;
                }
                font-size: 16px;
                font-weight: normal;
                line-height: 1.38;
                margin-top: 25px;
              }
            `}
          >
            <FormattedHTMLMessage
              id={`career.join-the-team.${name}.description`}
            />
          </div>
          <div
            onClick={() => {
              // 当前部门有职位招聘，滑动至相应部门的职位列表。当前部门无职位招聘，滑动至查看全部职位列表。
              var openJobsSection = document.querySelector(
                `.department-${name}`
              );
              if (openJobsSection) {
                window.scrollTo({
                  top: openJobsSection.offsetTop - 20,
                  left: 0,
                  behavior: 'smooth',
                });
              } else {
                var careerJobList = document.querySelector('.career-Job-List');
                window.scrollTo({
                  top: careerJobList.offsetTop - 20,
                  left: 0,
                  behavior: 'smooth',
                });
              }
            }}
            css={css`
              cursor: pointer;
              color: #ee2f79;
              text-decoration: underline;
              ont-size: 14px;
              font-weight: 500;
              line-height: 1.71;
              margin-top: 20px;
              ${Tablet} {
                font-size: 12px;
                font-weight: 500;
                line-height: 1.67;
                margin-top: 20px;
              }
              ${Desktop} {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.25;
                margin-top: 30px;
              }
            `}
          >
            {intl.formatMessage({
              id: `career.join-the-team.${name}.see-opportunities`,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
