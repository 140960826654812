import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Container from '../Container';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../../components/Blank';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
const CareerWorkWithAQM = ({ jobListRef }) => {
  const data = useStaticQuery(graphql`
    query {
      WorkWith: file(relativePath: { eq: "career/work-with-AQM.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      WorkWithMobile: file(
        relativePath: { eq: "career/work-with-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const intl = useIntl();
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        ${Tablet} {
          margin-top: 100px;
        }
        ${Desktop} {
          margin-top: 100px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          position: relative;
          ${Tablet} {
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            max-width: 768px;
          }
          ${Desktop} {
            max-width: 1440px;
          }
        `}
      >
        {/* 字 */}
        <div
          css={css`
            padding: 25px 22px 0 28px;
            height: 649px;
            ${Tablet} {
              height: auto;
              padding: 0;
            }
            ${Desktop} {
              margin-top: 181px;
            }
          `}
        >
          <div
            css={css`
              font-weight: bold;
              font-size: 32px;
              line-height: 36px;
              margin-bottom: 12px;
              ${Tablet} {
                font-weight: bold;
                font-size: 48px;
                line-height: 52px;
                max-width: 345px;
                margin-bottom: 20px;
              }
              ${Desktop} {
                font-weight: bold;
                font-size: 48px;
                line-height: 52px;
                max-width: 100%;
                margin-bottom: 5px;
              }
            `}
          >
            {intl.formatMessage({ id: `career.work-with.title` })}
          </div>
          <div
            css={css`
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 18px;
              ${Tablet} {
                font-weight: normal;
                font-size: 24px;
                line-height: 33px;
                max-width: 435px;
                margin-bottom: 30px;
              }
              ${Desktop} {
                font-weight: normal;
                font-size: 24px;
                line-height: 33px;
                max-width: 650px;
                margin-bottom: 30px;
              }
            `}
          >
            {intl.formatMessage({ id: `career.work-with.description` })}
          </div>
          <div
            onClick={() => {
              if (jobListRef) {
                const el = document.getElementById('___gatsby');
                el.scrollTo({
                  top: jobListRef.current.offsetTop - 50,
                  left: 0,
                  behavior: 'smooth',
                });
              }
            }}
            css={css`
              cursor: pointer;
              background: #ee2f79;
              border-radius: 41px;
              color: #ffffff;
              text-align: center;

              width: 118px;
              height: 28px;
              font-weight: bold;
              font-size: 12px;
              line-height: 28px;
              ${Tablet} {
                width: 201px;
                height: 47px;

                font-weight: bold;
                font-size: 20px;
                line-height: 47px;
              }
            `}
          >
            {intl.formatMessage({ id: `career.work-with.btn` })}
          </div>
        </div>
        {/* 间距 */}
        <div
          css={css`
            ${Tablet} {
              width: 32px;
            }
            ${Desktop} {
              width: 61px;
            }
          `}
        ></div>
        {/* 图 */}
        <div
          css={css`
            // border: 1px solid gray;
            width: 100%;
            height: 649px;
            position: absolute;
            z-index: -1;
            background: url(${data[`WorkWithMobile`].childImageSharp.fluid.src})
              no-repeat;
            background-size: cover;
            background-position: center 80%;
            ${Tablet} {
              background: url(${data[`WorkWith`].childImageSharp.fluid.src})
                no-repeat;
              background-size: cover;

              position: static;
              width: 240px;
              height: 359px;
              border-radius: 15px;
            }
            ${Desktop} {
              width: 492px;
              height: 735px;
              border-radius: 15px;
            }
          `}
        >
          <div
            css={css`
              display: block;
              height: 456.86px;
              width: 100%;
              position: absolute;
              background: linear-gradient(
                179.86deg,
                #ffffff -1.26%,
                rgba(196, 196, 196, 0) 103.96%
              );
              ${Tablet} {
                display: none;
              }
            `}
          ></div>
        </div>
      </div>
      <Blank height={[0, 150, 0]} />
      {/* 粉红色横幅 */}
      <div
        css={css`
          background: #ee2f79;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 424px;
          ${Tablet} {
            height: 407px;
          }
          ${Desktop} {
            height: 488px;
            margin-top: -120px;
            z-index: -2;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 25px;
            ${Tablet} {
              padding: 0 40px;
              max-width: 768px;
            }
            ${Desktop} {
              padding: 0 109px;
              max-width: 1440px;
            }
          `}
        >
          <div
            css={css`
              color: #ffffff;
              font-weight: normal;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              ${Tablet} {
                text-align: left;
                font-weight: normal;
                font-size: 24px;
                line-height: 33px;
              }
              ${Desktop} {
                font-weight: normal;
                font-size: 36px;
                line-height: 48px;
              }
            `}
          >
            <FormattedHTMLMessage id={`career.work-with.our-mission`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerWorkWithAQM;
