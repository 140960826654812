import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Container from '../Container';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../Blank';
import ValueCard from './ValueCard';
import GradientBackground from '../../components/GradientBackground';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useIntl } from 'gatsby-plugin-intl';
const CareerCorporatevalues = () => {
  const intl = useIntl();
  return (
    <>
      <Blank height={[100, 150, 150]} />
      {/* 主要内容 */}
      <div
        css={css`
          width: 100%;

          ${Tablet} {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          ${Desktop} {
          }
        `}
      >
        <div
          css={css`
            padding: 0 17px;
            text-align: center;

            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #161616;

            ${Tablet} {
              padding: 0;
              color: #000000;
              font-weight: 500;
              font-size: 26px;
              line-height: 33px;
              max-width: 635px;
            }
            ${Desktop} {
              font-weight: 500;
              font-size: 36px;
              line-height: 33px;
              max-width: 867px;
            }
          `}
        >
          {intl.formatMessage({ id: `career.corparate-values.title` })}
        </div>
        <Blank height={[40, 50, 100]} />
        {/* 卡片：WEB、IPAD端 */}
        <div
          css={css`
            display: none;
            ${Tablet} {
              display: flex;
            }
            ${Desktop} {
            }
          `}
        >
          <ValueCard name='create' />
          <Blank width={[0, 40, 50]} />
          <ValueCard name='spirit' />
        </div>
        <Blank height={[0, 40, 50]} />
        <div
          css={css`
            display: none;
            ${Tablet} {
              display: flex;
            }
            ${Desktop} {
            }
          `}
        >
          <ValueCard name='innovative' />
          <Blank width={[0, 40, 50]} />
          <ValueCard name='future' />
        </div>
        {/* 卡片：MOBILE端 */}
        <ScrollContainer
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 25px;
            ${Tablet} {
              display: none;
            }
          `}
        >
          <ValueCard name='create' />
          <ValueCard name='spirit' />
          <ValueCard name='innovative' />
          <ValueCard name='future' />
        </ScrollContainer>
      </div>

      {/* 渐变背景 */}
      <GradientBackground
        height={[0, 766, 875]}
        background={`linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #F2F2F2 100%)`}
        marginTop={[0, -616, -725]}
        css={css`
          z-index: -1;
        `}
      />
    </>
  );
};

export default CareerCorporatevalues;
